import DetailInfo from "./components/DetailInfo";
import linkedin from './img/linkedin.png';
import google_play from './img/google_play.png';
import github from './img/github.png';
import bitbucket from './img/bitbucket.png';

const references = [
    {
        icon: linkedin,
        href: "https://cz.linkedin.com/in/tomaschalupnik",
        alt: "View Tomáš Chalupník's profile on LinkedIn"
    },
    {
        icon: google_play,
        href: "https://play.google.com/store/apps/developer?id=Tom%C3%A1%C5%A1+Chalupn%C3%ADk",
        alt: "Google Play"
    },
    {
        icon: github,
        href: "https://github.com/wentsa",
        alt: "GitHub profile"
    },
    {
        icon: bitbucket,
        href: "https://bitbucket.org/wentsa/",
        alt: "Bitbucket profile"
    },
];

const App = () => (
  <div id="id_infobox">
    <div id="id_basic">
      <div id="id_name">
          <div>
              <div>Tomáš</div>
              <div>Chalupník</div>
          </div>
      </div>
      <div id="id_profession">Software developer</div>
    </div>
    <DetailInfo
        email="tchalupnik@tchalupnik.cz"
        references={references}
    />
  </div>
);

export default App;
