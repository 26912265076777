import React  from 'react';
import Image from './Image';

const DetailInfo = ({ references, email }) => (
    <div id="id_detailinfo">
        <a id="id_email" href={"mailto:" + email}>
            {email}
        </a>
        <div id="id_icons">
            {
                references.map(({
                    href,
                    alt,
                    icon
                }, idx) => (
                    <Image
                        key={`image-${idx}`}
                        href={href}
                        alt={alt}
                        icon={icon}
                    />
                ))
            }
        </div>
    </div>
);

export default DetailInfo;
